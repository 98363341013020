import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'ant-design-vue';
import {getGlobalSetting} from "../../globalSetting";

interface IRequestOptions {
  url: string;
  params?: object;
  data?: object;
  headers?: object;
  timeout?: number;
}

class Requester {
  private instance: AxiosInstance;
  private token?:string;

  constructor(config?: AxiosRequestConfig) {
    this.instance = axios.create(config);
  }

  public setToken(val:string){
    this.token = val
  }

  private getToken(){
    if(this.token) return this.token

    // 开发模式使用 跨端口 调试，两个前端都设置：
    // document.domain = 'localhost'
    
    const tokenKey = 'GJJT_BSBIDS__PRODUCTION__3.5.3__COMMON__LOCAL__KEY__'
    const tokenKeyDev = 'GJJT_BSBIDS__DEVELOPMENT__3.5.3__COMMON__LOCAL__KEY__'

    let tokenData:any = localStorage.getItem(tokenKey)
    if(!tokenData) tokenData = localStorage.getItem(tokenKeyDev)

    if(tokenData){
      return tokenData.TOKEN__;
    }

    return ''
  }

  private getRequestOptions(params:any = null):any{
    return {
        headers: {
            'Content-Type': 'application/json',
            'X-Access-Token': this.getToken()
        },
        params,
        // timeout: 30 * 1000
    }
  }

  public get(url:string, params:any):Promise<any> {
    return new Promise((resolve, reject) => {
        const options = this.getRequestOptions(params);
        this.instance.get(url, options).then(res => {
          this.checkResResponse(res)
          resolve(res.data);
        }).catch(err => { 
          this.onRequestError(url, err)
          // reject(null); 
        });
    });
  }

  public post(url:string, params:any): Promise<any> {
    return new Promise((resolve, reject) => {
        const options = this.getRequestOptions();
        this.instance.post(url, params, options).then(res => {
          this.checkResResponse(res)
          resolve(res.data);
        }).catch(err => { 
          this.onRequestError(url, err)
          // reject(null); 
        });
    });
  }

  public put(url:string, params:any): Promise<any> {
    return new Promise((resolve, reject) => {
        const options = this.getRequestOptions();
        this.instance.put(url, params, options).then(res => {
          this.checkResResponse(res)
          resolve(res.data);
        }).catch(err => { 
            this.onRequestError(url, err)
            // reject(null); 
          });
    });
  }

  private checkResResponse(res:any){
      if(res.status != 200){
        const errMsg = res.data?.message || '服务端错误'
        message.error(errMsg)
        // throw new Error(errMsg)
      }
      if(!res.data || !res.data.success){
        const errMsg = res.data?.message || '服务端发生异常'
        message.error(errMsg)
        // throw new Error(errMsg)
      }
  }

  private onRequestError(url:string, err:Error){
    const errMsg = '请求数据异常'

    console.error(url+' ' + errMsg); 
    console.error(err);

    message.error(errMsg)
    throw new Error(errMsg)
  }
}

console.log(getGlobalSetting().apiDomain)
const requester = new Requester({
  baseURL: getGlobalSetting().apiDomain,
  timeout: 30 * 1000
});

export default requester;