interface GlobalSetting {
    apiDomain: string,
    manageUrl: string
}

export const getGlobalSetting = (): GlobalSetting => {

    // 生产环境
    const setting = {
        apiDomain: 'https://api.njddzxcchdssjg.com/manage',
        manageUrl: 'https://operator.njddzxcchdssjg.com'
    }

    // 开发环境配置
    // if (process.env.NODE_ENV === 'development') {
    //     // setting.apiDomain = 'http://njddzxcchdssjg.com:7003/bike/open-api/v1'
    //     setting.apiDomain = 'http://10.0.9.249:7003/bike'
    //     setting.manageUrl = 'http://10.0.9.249:7004'
    // }

    return setting;
}