<template>
  <div class="header-zone">
    <div>南京市电动自行车集中充（换）电设施建设运营监管服务平台</div>
    <div>{{ timeStr }}</div>
  </div>
  <div class="header-bg">
    <div class="">{{ title }}</div>
  </div>
</template>
<script>
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const week = date.getDay();
  const weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const weekStr = weekArr[week];
  const _timeStr = `${year}年${month}月${day}日 ${weekStr}`;
  export default {
    name: 'CommonHeader',
    props: {
      title: {
        type: String,
        default: '',
      }, // 时间字符串
    },
    data() {
      return {
        timeStr: _timeStr,
      };
    },
  };
</script>
<style scoped lang="less">
.header-zone{
  background-color: #0061A4;
  color: #fff;
  font-size: 16px;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
}
  .header-bg {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(@/assets/ddc_web_008.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0060A5;
    font-size: 40px;
    font-weight: 600;
  }
</style>
