<template>
  <div
    class="notice-btn-zone"
    @click="handleGoLogin"
  >
    监管服务平台
  </div>
</template>
<script lang="ts" setup name="CommonFooter">
import {getGlobalSetting} from "@/globalSetting";
  import { useRouter } from 'vue-router';

  const router = useRouter();

  const handleGoLogin = () => {
    //在新窗口打开vue-router的登录路由页面
    window.open(getGlobalSetting().manageUrl, '_blank');
  };
</script>
<style scoped lang="less">
  .notice-btn-zone {
    box-shadow: 0px 4px 2px 0px rgba(0, 39, 169, 0.2);
    border: 1px solid #1890ff;
    width: 240px;
    margin: 0 auto;
    background-color: #ffffff;
    color: #1890ff;
    font-size: 18px;
    height: 48px;
    line-height: 46px;
    text-align: center;
    cursor: pointer;
  }
</style>
