<template>
    <div class="home-container">
      <header>
        <CommonHeader title="南京市电动自行车集中充（换）电设施建设运营监管服务平台公告"/>
      </header>
      <main class="main-container flex justify-center">
        <div class="detail-zone" v-if="!isShowDetail">
          <div class="search-zone flex justify-start space-x-3">
            <div class="search-zone-input">
              <a-input placeholder="请输入关键字搜索" v-model:value="searchKey"/>
            </div>
            <a-button type="primary" style="margin-left: 10px;" @click="handleSearch(true)">查询</a-button>
            <a-button type="default" style="margin-left: 10px;" @click="handleReset">重置</a-button>
          </div>
          <div class="content-zone">
            <div class="content-zone-header">
              <div class="text-center" style="width: 80%">公告名称</div>
              <div class="text-center" style="width: 20%">发布日期</div>
            </div>
            <div class="content-zone-body">
              <div
                  class="content-item"
                  v-for="(item, index) in noticeData"
                  :key="index"
                  @click="handleDetail(item)"
              >
                <div class="content-item-title">{{ item.title }}</div>
                <div class="text-center">{{ item.createTime }}</div>
              </div>
            </div>
            <div class="pagination-zone">
              <Pagination
                  size="small"
                  v-model:current="current"
                  v-model:page-size="pageSize"
                  showSizeChanger
                  :total="total"
                  :show-total="(total, range) => `第${range[0]}-${range[1]}条 / 共 ${total} 条`"
                  @change="onChange"
              />
            </div>
          </div>
        </div>
        <div class="detail-zone detail-info-zone" v-if="isShowDetail">
          <div>
            <a-breadcrumb separator=">">
              <a-breadcrumb-item style="cursor: pointer;" @click="isShowDetail = false">返回公告列表</a-breadcrumb-item>
              <a-breadcrumb-item>{{ detail.title }}</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
          <div class="detail-info-zone-title">
            {{ detail.title }}
          </div>
          <div class="detail-info-zone-date m-y-[2px]">
            <span class="text-[#999999]">发布日期：{{ detail.createTime }} </span>
          </div>
          <a-divider/>
          <div v-html="sanitizedContent(detail.content)"></div>
          <div class="file-zone m-t-[20px] flex" v-if="detail.fileUrls && detail.fileUrls.length > 0">
            <div class="file-zone-label m-t-[8px] text-[#FF0000]">附件：</div>
            <a-upload v-model:file-list="detail.fileUrlsArray" :showUploadList="{
        showPreviewIcon: false,
        showDownloadIcon: true,
        showRemoveIcon: false,
      }">

            </a-upload>
          </div>
        </div>
      </main>
      <footer class="" style="margin-top: 8px;">
        <CommonFooter/>
      </footer>
    </div>
</template>

<script setup lang="ts" name="home">
//获取当前日期 2024年5月10日 星期五
import {onMounted, ref, unref} from 'vue';
import {Pagination} from 'ant-design-vue';
import {api} from '@/api/Api';
import {useRouter} from 'vue-router';
import DOMPurify from 'dompurify';
import CommonHeader from './CommonHeader.vue';
import CommonFooter from './CommonFooter.vue';
import {UploadOutlined} from '@ant-design/icons-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const date = new Date();
const year = date.getFullYear();
const month = date.getMonth() + 1;
const day = date.getDate();
const week = date.getDay();
const weekArr = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
const weekStr = weekArr[week];
const timeStr = `${year}年${month}月${day}日 ${weekStr}`;
// console.log(dateStr);

const router = useRouter();

const searchKey = ref<string>();

const noticeData = ref([]);

const pageSize = ref<any>(10);
const current = ref<any>(1);
const total = ref<any>(100);

//分页事件
const onChange = (page, pageSizeCount) => {
  current.value = page;
  pageSize.value = pageSizeCount;
  handleSearch();
};

//搜索事件
const handleSearch = async (isRestPage = false) => {
  isRestPage && (current.value = 1);
  let params = {
    pageNo: unref(current),
    pageSize: unref(pageSize),
    title: unref(searchKey),
    auditStatus: 1,
  };
  const result = await api.externalList(params);
  const res = result.result
  total.value = res.total;
  noticeData.value = res.records.map((item) => {
    return {
      ...item,
      fileUrlsArray: item.fileUrls ? (item.fileUrls.split(',').map(fileItemUrl => {
        return {
          name: fileItemUrl.split('/').pop(),
          url: fileItemUrl,
        }
      })) : [],
    }
  });
  console.log('noticeData', noticeData.value)
};

const handleReset = () => {
  searchKey.value = '';
  handleSearch(true);
}; //重置事件
//查看详情
const isShowDetail = ref<boolean>(false);
const detail = ref<any>({});
const handleDetail = (item) => {
  console.log('detail', item);
  isShowDetail.value = true;
  detail.value = {...item};
};

//dom 清理
const sanitizedContent = (content: string) => {
  return DOMPurify.sanitize(content);
  // return content;
};
onMounted(() => {
  handleSearch(true);
});
</script>

<style scoped lang="less">
.content-zone-body {
  border-left: 1px solid #e2e2e2;
  border-right: 1px solid #e2e2e2;
}

.text-center {
  text-align: center;
}

.home-container {
  width: 100%;
  height: 100%;

  overflow-y: auto;

  .main-container {
    display: flex;
    justify-content: center;

    .detail-zone {
      --un-bg-opacity: 1;
      background-color: rgba(255, 255, 255, var(--un-bg-opacity));
      width: 1200px;
      height: 100%;
      padding: 16px 20px;

      .search-zone {
        display: flex;
        justify-content: flex-start;

        .search-zone-input {
          width: 200px;
        }
      }

      .content-zone {
        margin-top: 20px;

        .content-zone-header {
          font-size: 16px;
          background-color: #D2E9FF;
          display: flex;
          align-items: center;
          height: 40px;
          color: #334B88;
        }

        .content-zone-body {
          .content-item {
            display: flex;
            align-items: center;
            height: 56px;
            font-size: 16px;
            border-bottom: 1px solid #e2e2e2;
            cursor: pointer;

            .content-item-title {
              width: 80%;
              padding-left: 20px;
            }

          }
        }

        .pagination-zone {
          margin-top: 20px;
          text-align: center;
        }
      }

    }
  }

  .detail-info-zone {
    .detail-info-zone-title {
      font-size: 40px;
      color: #333333;
      line-height: 54px;
      margin-top: 29px;
      margin-bottom: 20px;
    }

    .detail-info-zone-date {
      margin: 2px 0;

      span {
        color: #999999;
      }
    }

    .file-zone {
      margin-top: 20px;
      display: flex;

      .file-zone-label {
        margin-top: 8px;
        color: #FF0000;
      }
    }
  }
}


</style>