import RequestUtil from './utils/RequestUtil';

export enum Api {

    // 公示列表
    noticeList = '/notice/list',
    externalList = '/information/externalList',
}

export const api = {
    noticeList: (params:any) => RequestUtil.get(Api.noticeList, params),
    externalList: (params:any) => RequestUtil.get(Api.externalList, params),
}