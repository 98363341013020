import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const dayjs = require('dayjs');
require('dayjs/locale/zh-cn'); // 导入中文语言包
dayjs.locale('zh-cn');

import { Button, Tabs, message,Input,Breadcrumb,Divider,Upload,ConfigProvider } from 'ant-design-vue';

const app = createApp(App);

/* 会自动注册 Button 下的子组件, 例如 Button.Group */
app.use(store).use(router)
.use(Button).use(Tabs).use(Input).use(Breadcrumb).use(Divider).use(Upload).use(ConfigProvider)
.mount('#app');

app.config.globalProperties.$message = message;